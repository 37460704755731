/* Navigation Bar  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.logo {
    background-color: "#181c27";
}

.hamBtn,
.navitems-sm {
    display: none;
}

.navitems-lg {
    a {
        font-weight: 550;
        font-size: 1rem;
        color: #292929;
    }

    a:hover {
        /* color: #c79303; */
    }
}


/* Media Queries */

@media (max-width:1100px) {
    .hamBtn {
        display: flex;
    }

    .navitems-lg {
        display: none;
    }

    .navitems-sm {
        display: flex;
        position: fixed;
        background-color: #fff;
        left: 0;
        height: 100vh;
        font-size: 18px;
        padding: 70px 0px;
        /* font-size: 2.20rem; */
        font-weight: normal;
        letter-spacing: 0.00rem;
        line-height: 1.55;
        text-transform: none;
        font-family: "Inter";
        width: 60vw;
        top: 0;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        transform: translateX(-100%);
        transition: all .2s linear;
        button{
            position: fixed;
            top: 27px;
            right: 30px;
        }
    }

    .showNav {
        transform: translateX(0);
        transition: all .2s linear;
    }
}


/* tranfroming nav on scroll */

nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 20px 30px;
    transition: all .1s linear;
    z-index: 990;
}

.nav-fixed {
    position: fixed;
    padding: 10px 18px;
    transition: all .1s linear;
    z-index: 990;
}