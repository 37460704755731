@tailwind utilities;
@tailwind components;
@tailwind base;

a {
    /* color: #c0a353; */
    text-decoration: none;
}

li {
    list-style: none;
}



body{
    min-height:120vh;
}

.card-img{
    height: 200px;
}